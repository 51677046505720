/* Footer.css */

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Set your desired background color */
  color: #9ad5cf; /* Set text color */
  text-align: center; /* Center the contents horizontally */
}

.p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.footer-content {
  max-width: 1200px; /* Limit the width of content */
  margin: 0 auto; /* Center the content horizontally */
}
