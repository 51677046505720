/* Navbar.css */

.navbar {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 0;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px; /* Adjust according to your layout */
  margin: 0 auto;
  padding: 0 20px;
}

.logo-container {
  flex: 1; /* This will make the logo take up all available space */
}

.logo {
  max-width: 100px; /* Adjust according to your logo size */
}

.nav-links {
  display: flex;
}

.nav-links a {
  color: white;
  text-decoration: none;
  margin-left: 20px; /* Adjust spacing between links */
}
