.center-page {
  /*  display: flex;
  justify-content: center; /* Center horizontally
  align-items: center; Center vertically */
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  margin-bottom: 5px;
}

input[type='text'],
input[type='email'],
input[type='number'],
textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 85%;
  border: none;
}

textarea {
  resize: vertical;
}

button {
  padding: 10px 20px;
  background-color: rgba(64, 112, 145, 255);
  color: white;
  letter-spacing: 2px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: rgba(153, 212, 207, 255);
}

.social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15%;
}

.social a {
  color: rgba(64, 112, 145, 255);
  font-size: 30px;
}
